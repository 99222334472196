

















































































import { Component, Vue } from "vue-property-decorator";
import { companyAppointPageList } from "@/serve/medicalAppointment/plan";
@Component({
  name: "sourceReport",
})
export default class MedicalReport extends Vue {
  public tableData: any = [];
  public form = {
    //搜索单位名称
    name: "",
    //当前页面
    current: 1,
    //每页数量
    size: 10,
  };
  //分页器参数
  public pagination = {
    //每页数量
    siez: 0,
    //总页
    total: 0,
    //当前页
    current: 0,
  };

  public currentDate = new Date(); // 获取当前日期
  public dateArray: any = []; // 初始化日期数组
  public created() {
    //获取列表
    this.getReportList();
    for (let i = 0; i < 10; i++) {
      // 循环8天，包括今天和之后的7天
      const nextDate = new Date(this.currentDate);
      nextDate.setDate(this.currentDate.getDate() + i); // 增加日期
      const formattedDate = nextDate.toISOString().split("T")[0]; // 格式化日期字符串
      this.dateArray.push(formattedDate); // 使用动态键存储到对象中
    }
  }
  private getReportList() {
    companyAppointPageList(this.form).then((res: any) => {
      console.log("res", res);
      this.tableData = res.records;
      this.pagination.siez = res.size;
      this.pagination.total = res.total;
      this.pagination.current = res.current;
    });
  }

  //重置搜索
  private reset() {
    (this.form.current = 1), (this.form.size = 10), (this.form.name = "");
    this.getReportList();
  }
  //提交搜索
  public submitSercher() {
    this.form.size = 10;
    this.form.current = 1;
    this.getReportList();
  }
  // 点击左右箭头
  public handleSizeChange(val: any): void {
    this.form.size = val;
    this.getReportList();
  }
  // 点击页码
  public handleCurrentChange(val: any): void {
    this.form.current = val;
    this.getReportList();
  }
}
